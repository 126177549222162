import axios from '@/utils/http';
export const submitData = (params) => axios.request({
    // ...options,
    url: '/api/system/project/add',
    method: 'post',
    data: params
});

export const upload = (formData) => axios.request({
    url: '/api/common/uploads',
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
});